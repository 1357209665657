import React from "react"
import Obfuscate from "react-obfuscate"

import { Link } from "gatsby"
import Grid from "./grid"

export default () => (
  <div>
    <div id="footer" className="uk-section uk-section-primary primary-transparent">
      <div className="uk-container uk-text-center uk-padding-large">
        <Grid Col="3">
          <div>
            <div className="uk-card uk-padding">
              <h3 className="uk-card-title">
                <Link to="/contact/" className="uk-link-heading">
                  Contact
                </Link>
              </h3>
              <ul className="uk-list">
                <li>Gus LYON</li>
                <li>
                  Tél : <Obfuscate tel="06 80 82 97 62" className="uk-link-text" />
                </li>
                <li>
                  Email :{" "}
                  <Obfuscate
                    email="contact@guslyon.fr"
                    headers={{
                      subject: "Message depuis GusLyon.fr"
                    }}
                    className="uk-link-text"
                  />
                </li>
              </ul>
              <Link className="uk-button uk-button-default" to="/contact/">
                Ecrire un message
              </Link>
            </div>
          </div>

          <div>
            <div className="uk-card uk-padding">
              <h3 className="uk-card-title">Entreprise</h3>
              <ul className="uk-list">
                <li>
                  SIRET :{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="uk-link uk-link-text"
                    href="https://www.infogreffe.fr/entreprise-societe/829370022-lyon-gus-82937002200016.html"
                  >
                    829 370 022 00016
                  </a>
                </li>
                <li>
                  APE :{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="uk-link uk-link-text"
                    href="https://www.insee.fr/fr/metadonnees/nafr2/sousClasse/62.01Z"
                  >
                    6201Z
                  </a>
                </li>
                <li>
                  TYPE :{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="uk-link uk-link-text"
                    href="https://www.autoentrepreneur.urssaf.fr/portail/accueil/sinformer-sur-le-statut/lessentiel-du-statut.html"
                  >
                    auto-entrepreneur
                  </a>
                </li>
                <li>ADRESSE : </li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="uk-link uk-link-text"
                  href="https://www.google.com/maps/place/45%C2%B027'03.6%22N+6%C2%B058'20.2%22E/@45.4510029,6.9711697,18z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d45.4510013!4d6.9722642"
                >
                  <li>
                    Chemin du Petit Alaska
                    <br />
                    Chalet ELAN
                    <br />
                    73150 Val-d'Isère
                  </li>
                </a>
              </ul>
            </div>
          </div>

          <div>
            <div className="uk-card uk-padding">
              <h3 className="uk-card-title">Liens</h3>
              <ul className="uk-list">
                <li>
                  <span className="uk-margin-small-right" data-uk-icon="menu" />
                  <Link className="uk-button uk-button-text" to="/sitemap/">
                    Plan du site
                  </Link>
                </li>

                <li>
                  <span className="uk-margin-small-right" data-uk-icon="facebook" />
                  <a target="_blank" rel="noopener noreferrer" className="uk-button uk-button-text" href="https://www.facebook.com/guslyon.fr/">
                    Facebook
                  </a>
                </li>

                <li>
                  <span className="uk-margin-small-right" data-uk-icon="youtube" />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="uk-button uk-button-text"
                    href="https://www.youtube.com/channel/UC1Pt8odPi6Pg-E110AplKkA"
                  >
                    YouTube
                  </a>
                </li>

                <li>
                  <span className="uk-margin-small-right" data-uk-icon="linkedin" />
                  <a target="_blank" rel="noopener noreferrer" className="uk-button uk-button-text" href="https://www.linkedin.com/company/guslyon">
                    LinkedIn
                  </a>
                </li>

                <li>
                  <span className="uk-margin-small-right" data-uk-icon="twitter" />
                  <a target="_blank" rel="noopener noreferrer" className="uk-button uk-button-text" href="https://twitter.com/LyonGus">
                    Twitter
                  </a>
                </li>

                <li>
                  <span className="uk-margin-small-right" data-uk-icon="instagram" />
                  <a target="_blank" rel="noopener noreferrer" className="uk-button uk-button-text" href="https://www.instagram.com/lyon.gus">
                    Instagram
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Grid>
      </div>
    </div>
    <div className="uk-section uk-section-secondary secondary-transparent uk-padding-remove">
      <div className="uk-container uk-text-center uk-margin-top uk-margin-large-bottom" data-uk-scrollspy="cls: uk-animation-slide-bottom-medium">
        <Link to="/#" data-uk-totop="" data-uk-scroll />
        <ul className="uk-list">
          <li>Copyright © GusLyon.fr - Conception web 2018</li>
          <li>
            Ergonomie graphique :{" "}
            <a href="http://www.julienharant.com/" target="_blank" rel="noopener noreferrer" className="uk-link-text">
              Julien HARANT
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
)
