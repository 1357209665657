import React from "react"

import Background from "../components/background"
import Header from "../components/header"
import Footer from "../components/footer"

export default class extends React.Component {
  state = { ready: false }

  componentDidMount = () => {
    if (typeof window !== "undefined") {
      require("../styles/uikit.theme.css")
      require("../styles/custom.css")
      const UIkit = require("uikit")
      const Icons = require("uikit/dist/js/uikit-icons.min")
      UIkit.use(Icons)
      this.setState({ ready: true })
    }
  }

  render() {
    return (
      <div>
        <noscript>
          <div className="uk-text-center uk-padding-large">
            <p>
              <strong>GusLyon.fr</strong>
            </p>
            <p>Pour accéder à toutes les fonctionnalités de ce site, vous devez activer JavaScript.</p>
            <p>
              Voici comment autoriser l'exécution de JavaScript dans votre navigateur Web :<br />
              <a href="https://www.enable-javascript.com/fr/" target="_blank" rel="noopener noreferrer">
                www.enable-javascript.com/fr
              </a>
            </p>
          </div>
        </noscript>

        {this.state.ready && (
          <div>
            <div className="uk-position-fixed uk-width-expand" id="background">
              <Background />
            </div>

            <div className="uk-position-relative">
              <Header />

              {this.props.children}

              <Footer />
            </div>
          </div>
        )}
      </div>
    )
  }
}
