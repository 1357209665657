import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default () => (
  <div>
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "styles/background.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 2500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <div className="uk-cover-container">
          <Img fluid={data.file.childImageSharp.fluid} />
        </div>
      )}
    />
  </div>
);
