import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default () => (
  <div>
    <div
      className="uk-section uk-padding-remove-vertical uk-section-primary uk-box-shadow-large"
      data-uk-sticky="show-on-up: true; animation: uk-animation-slide-top"
    >
      <div className="uk-container">
        <nav id="navbar" data-uk-navbar>
          <div className="uk-navbar-left">
            <Link to="/" className="uk-button uk-button-text">
              {siteLogo}
            </Link>
          </div>
          <div className="uk-navbar-right">
            <ul className="uk-navbar-nav uk-visible@m uk-height-1-1">
              <ListLink to="/projects/">Réalisations</ListLink>
              <ListLink to="/services/">Services</ListLink>
              <ListLink to="/skills/">Compétences</ListLink>
              <ListLink to="/about/">À propos</ListLink>
              <ListLink to="/contact/">Contact</ListLink>
            </ul>

            {/* <Link className="uk-link-heading uk-hidden@m" to="/menu/">
              <h5 className="uk-margin-remove">
                <span
                  className="uk-margin-small-right"
                  data-uk-icon="icon: menu; ratio: 2"
                />
                MENU
              </h5>
            </Link> */}

            <Link
              to="/menu/"
              className="uk-link-heading uk-hidden@m"
              aria-label="menu"
            >
              <span
                data-uk-icon="icon: menu; ratio: 1.5"
                className="uk-margin-small-left"
              />
            </Link>
          </div>
        </nav>
      </div>
    </div>
  </div>
);

const ListLink = props => (
  <li>
    <Link
      to={props.to}
      className="uk-button uk-button-text item-menu"
      activeClassName="active-menu"
    >
      {props.children}
    </Link>
  </li>
);

// const siteName = (
//   <StaticQuery
//     query={graphql`
//       query {
//         site {
//           siteMetadata {
//             title
//           }
//         }
//       }
//     `}
//     render={data => <div>{data.site.siteMetadata.title}</div>}
//   />
// );

const siteLogo = (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "pages/logo-guslyon-light.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div id="logo">
        <Img
          fluid={data.file.childImageSharp.fluid}
          className="uk-margin-top uk-margin-bottom"
        />
      </div>
    )}
  />
);
