import React from "react";

const loadClass = Col => {
  if (Col === "5") {
    return "uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-5@xl";
  } else if (Col === "4") {
    return "uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l";
  } else if (Col === "3") {
    return "uk-child-width-1-2@s uk-child-width-1-3@m";
  } else {
    return "uk-child-width-1-2@s";
  }
};

export default props => {
  return (
    <div
      id={props.Id}
      className={"uk-flex uk-flex-center " + loadClass(props.Col)}
      data-uk-grid
      // data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: .uk-card; delay: 250"
      data-uk-height-match="target: .uk-card-body"
    >
      {props.children}
    </div>
  );
};
