import React from "react";
// import posed from "react-pose";
// import PageTransition from "gatsby-plugin-page-transitions";

// const Transition = posed.div({
//   enter: {
//     opacity: 1,
//     filter: "blur(0px)"
//   },
//   exit: {
//     opacity: 0,
//     filter: "blur(10px)"
//   }
// });

export default props => (
  <div
    className={"uk-preserve-color uk-section uk-section-" + props.Style}
    data-uk-height-viewport={props.Viewport}
    id={props.Id}
  >
    <div className="uk-container uk-animation-fade">
      {/* <PageTransition> */}
      {/* <Transition> */}
      {props.children}
      {/* </Transition> */}
      {/* </PageTransition> */}
    </div>
  </div>
);
