import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

export default props => (
  <div>
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              description
              author
              siteUrl
            }
          }
          file(relativePath: { eq: "pages/avatar-light.png" }) {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      `}
      render={data => {
        const LoadPageDesc = description => {
          if (description) {
            return description;
          } else {
            return data.site.siteMetadata.description;
          }
        };
        const LoadPageImg = image => {
          if (image) {
            return data.site.siteMetadata.siteUrl + image;
          } else {
            return (
              data.site.siteMetadata.siteUrl +
              data.file.childImageSharp.original.src
            );
          }
        };

        return (
          <div>
            <Helmet>
              <meta
                http-equiv="Content-Type"
                content="text/html; charset=utf-8"
              />
              <html lang="fr" />
              <title>
                {props.PageTitle + " - " + data.site.siteMetadata.title}
              </title>
              <meta
                name="description"
                content={LoadPageDesc(props.PageDescription)}
              />
              <meta name="author" content={data.site.siteMetadata.author} />
              <link
                rel="canonical"
                href={data.site.siteMetadata.siteUrl + props.PageSlug}
              />
              <link rel="image_src" href={LoadPageImg(props.PageImage)} />

              {/* === Facebook Open Graph === */}
              <meta
                property="og:site_name"
                content={data.site.siteMetadata.title}
              />
              <meta
                property="og:url"
                content={data.site.siteMetadata.siteUrl + props.PageSlug}
              />
              <meta property="og:type" content="article" />
              <meta
                property="og:title"
                content={props.PageTitle + " - " + data.site.siteMetadata.title}
              />
              <meta
                property="og:description"
                content={LoadPageDesc(props.PageDescription)}
              />
              {/* og:image : min-width = 200px & min-height = 200px */}
              <meta
                property="og:image"
                content={LoadPageImg(props.PageImage)}
              />
              <meta property="og:locale" content="fr_FR" />

              {/* === Twitter Cards === */}
              <meta name="twitter:card" content="summary" />
              <meta
                name="twitter:site"
                content={data.site.siteMetadata.title}
              />
              <meta
                name="twitter:title"
                content={props.PageTitle + " - " + data.site.siteMetadata.title}
              />
              <meta
                name="twitter:description"
                content={LoadPageDesc(props.PageDescription)}
              />
              <meta
                name="twitter:image"
                content={LoadPageImg(props.PageImage)}
              />
            </Helmet>
          </div>
        );
      }}
    />
  </div>
);
